* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080708;
  color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
}

.nav-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.nav-links li {
  padding: 0 1rem;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
}

#hero {
  text-align: center;
  padding: 2rem;
  position: relative; /* Make sure this is relative to position the pseudo element absolutely within it */
}

#hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%; /* This decides how much of the top part you want to cover */
  background: linear-gradient(to bottom, rgba(20, 19, 19, 0.65), transparent);
  z-index: 1; /* Ensure it's above the background but below the text and other content */
}

/* Ensure other content within #hero is positioned above the overlay */
#hero * {
  position: relative;
  z-index: 2;
}

button {
  background-color: #555;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
  margin: auto;
  margin-top: 60px;
}

@media screen and (max-width: 600px) {
  .imageGrid {
    grid-template-columns: repeat(2, 1fr); /* Two images per row */
  }
}